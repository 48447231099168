/* Offcanvas menu */
jQuery(function($) {

  // Pannello & pulsante
  var $btn = $('[data-toggle="offcanvas"]'),
      $pnl = $('#offcanvas');
  $btn.click(function(e) {
    e.preventDefault();
    $pnl.toggleClass('open');
    var on = $pnl.is('.open');
    $btn.toggleClass('active', on).attr('aria-expanded', on);
    if ( on ) { $pnl.focus(); }
  }).attr('aria-expanded',false);
  $pnl.click(function(e) {
    if ($(e.target).is($pnl) && $(this).is('.open')) {
      $(this).removeClass('open');
      $btn.removeClass('active').attr('aria-expanded',false).focus();
    }
  }).attr('tabindex',-1);
  $(document).keyup(function(e) {
    // Bind key esc
    if (e.which === 27) {
      $btn.click().focus();
    }
  });

});
